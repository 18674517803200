import { ConfigValue } from './ConfigValue'

export type ConfigKeys = keyof typeof defaultConfig
export type ConfigType = Record<ConfigKeys, any>
export type RemoteConfigType = Record<keyof ConfigType, ConfigValue>

export const defaultConfig = {
  test: 'true', // for testing purposes
  enableShipmentDocuments: 'false',
  showPTL: 'true',
  showOnboardingImprovements: 'true',
  showFacilityScore: 'true',
  showKamionLink: 'false',
  kamionIntegrationSurvey:
    '{ "text": "", "textFeedback": "", "feedbackDelay": 0, "buttonLabel": "", "enabled": false, "roleRestriction": [], "storageKey": "", "target": ""}',
  enableBrokerSignup: 'false',
  showShipperLoads: 'false',
  advertisingBannerConfig: JSON.stringify({
    '5': {
      display: 'false',
      bannerText: '',
      bannerTextVariant: '',
      buttonText: '',
      bannerBackgroundColor: '',
      bannerTextColor: '',
      buttonVariant: '',
      buttonURL: '',
      buttonTextColor: '',
      bannerBackgroundImage: '',
    },
  }),
  enableQuoteAccessorials: 'false',
  enableAgnosticShipmentsFilter: 'false',
  enableShipmentAssetCarrierUpdate: JSON.stringify({ shippers: [] }),
  enableShipmentChargesForFTL: 'false',
  enableShipmentChargesForIMDL: 'false',
  enableShipmentChargesForBrokerage: 'false',
  enableShipmentChargesForMtc: 'false',
  enableShipmentChargesForSaas: 'false',
  enableShipmentChargesForFbm: 'false',
  enableShipmentAppointment: 'false',
  enableTenderRejectReason: 'false',
  configTopLanes: '[]',
  configAnnouncement: '{}',
  enablePriceItemTypes: 'false',
  enableNewPreferredLanesPage: 'false',
  enableShipmentNumberFilter: 'false',
  enableMultiModeRFP: 'false',
  enableChat: 'false',
}

export function getConfigValues(config: ConfigType) {
  return Object.entries(config).reduce((acc: RemoteConfigType, current: [string, string]) => {
    const [configName, value] = current

    const configValue = new ConfigValue(value)

    return { ...acc, [configName]: configValue }
  }, {} as RemoteConfigType)
}

export const defaultConfigValues = getConfigValues(defaultConfig)
